import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "components/Header/Header";
import { Link, useLocation } from "react-router-dom";
import { PathName } from "routers/types";

export type SiteHeaders = "Header 1" | "Header 2";

interface HomePageItem {
    name: string;
    slug: PathName;
}

let OPTIONS = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0, 
}

let OBSERVER: IntersectionObserver | null = null;
const PAGES_HIDE_HEADER_BORDER: PathName[] = [
    "/",
    "/",
    "/motor",
    "/baby-equipment",
    "/tour-package",
    "/recharge",
    "/shop",
]

const SiteHeader = () => {

    const anchorRef = React.useRef<HTMLDivElement>(null);

    let[headers] = React.useState<SiteHeaders[]>([
        "Header 1",
        "Header 2",
    ]);

    const [headerSelected, setHeaderSelected] =
    React.useState<SiteHeaders>("Header 1");

    const [isTopOfPage, setIsTopOfPage] = React.useState(window.pageYOffset < 5);
    const location = useLocation();

    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      setIsTopOfPage(entry.isIntersecting);
    });
    };

    useEffect(() => {
    if (location.pathname === "/") {
      setHeaderSelected("Header 2");
    }
    // disconnect the observer
    if (!PAGES_HIDE_HEADER_BORDER.includes(location.pathname as PathName)) {
      OBSERVER && OBSERVER.disconnect();
      OBSERVER = null;
      return;
    }
    if (!OBSERVER) {
      OBSERVER = new IntersectionObserver(intersectionCallback, OPTIONS);
      anchorRef.current && OBSERVER.observe(anchorRef.current);
    }
    }, [location]);
    return (
        <>
        <Helmet>
            <title>MainkeBali</title>
        </Helmet>
        <Header className="shadow-sm dark:border-b dark:border-neutral-700"/>
        <div ref={anchorRef} className="h-1 absolute invisible"></div>
        </>
    );
};

export default SiteHeader;
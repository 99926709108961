import { MOTOR_LISTING } from "data/listings";
import { MotorDataType } from "data/types";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";

export interface CardMotorProps {
  className?: string;
  data?: MotorDataType;
  size?: "default" | "small";
}

const MOTOR_DATA: MotorDataType = MOTOR_LISTING[0];

const CardMotor: FC<CardMotorProps> = ({
  size = "default",
  className = "",
  data = MOTOR_DATA,
}) => {
  const { featuredImage, title, href, priceDay, priceWeek, seats, tahunMotor } =
    data;

  const renderHeaderImage = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden">
        <div className=" aspect-w-16 aspect-h-9 mt-4">
          <NcImage
            containerClassName="flex items-center justify-center"
            className="h-full w-full"
            src={featuredImage}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-5  space-y-4" : "p-3  space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center space-x-2 text-center justify-center">
            <h2
              className={`  capitalize ${
                size === "default"
                  ? "text-xl font-semibold"
                  : "text-base font-medium"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-center justify-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <span className="">{seats} seats</span>
            <span>-</span>
            <span className="">{tahunMotor} </span>
          </div>
        </div>
        <div className="w-14  border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-sm font-semibold">
            {priceDay}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /hari
              </span>
            )}
          </span>
          <span className="text-sm font-semibold">
            {priceWeek}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /Mingguan
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform ${className}`}
      data-nc-id="CarCard"
    >
      <Link to={href} className="flex flex-col">
        {renderHeaderImage()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default CardMotor;

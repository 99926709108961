import __taxonomies from "./jsons/__taxonomies.json";
import __wisataTaxonomies from "./jsons/__tourTaxonomies.json";
import __kulinerTaxonomies from "./jsons/__culinaryTaxonomies.json";
import __motorTaxonomies from "./jsons/__motorTaxonomies.json";
import { TaxonomyType } from "./types";

const DEMO_CATEGORIES: TaxonomyType[] = __taxonomies.map((item) => ({
  ...item,
  taxonomy: "category",
}));

const DEMO_TAGS: TaxonomyType[] = __taxonomies.map((item) => ({
  ...item,
  taxonomy: "tag",
}));

const WISATA_CATEGORIES: TaxonomyType[] = __wisataTaxonomies.map((item) => ({
  ...item,
  taxonomy: "category",
  listingType: "wisata",
}));
//
const KULINER_CATEGORIES: TaxonomyType[] = __kulinerTaxonomies.map((item) => ({
  ...item,
  taxonomy: "category",
  listingType: "kuliner",
}));

const MOTOR_CATEGORIES: TaxonomyType[] = __motorTaxonomies.map((item) => ({
  ...item,
  taxonomy: "category",
  listingType: "motor",
}));

export {
  DEMO_CATEGORIES,
  DEMO_TAGS,
  MOTOR_CATEGORIES,
  WISATA_CATEGORIES,
  KULINER_CATEGORIES,
};

import React from "react";
import SectionProduct from "components/SectionProduct/SectionProduct";
import SectionDestinationFavorite from "components/SectionSliderCategory/SectionSliderCategory";
import SectionWhyChooseMe from "components/SectionWhyChooseMe/SectionWhyChooseMe";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridCategoryTab from "components/SectionGridCategoryTab/SectionGridCategoryTab";
import headerImg from "images/travelhero2.png";
import SectionDowloadApp from "./SectionDownloadApp";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      <div className="relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
      <div className="lg:flow-root">
        <div className="relative w-full h-40 md:h-60 2xl:h-72">
          <div className="nc-NcImage absolute inset-0 " data-nc-id="NcImage">
            <img className="object-cover w-full h-full rounded-sm" src={headerImg} />
          </div>
        </div>
      </div>
      </div>
      <div className="relative space-y-24 mb-14 lg:space-y-14 md:mb-18 lg:mb-20">
        {/* SECTION HERO */}
        <div className="lg:flow-root">
        <div className="md:container lg:container xl:container relative -mt-28 md:-mt-24 lg:-mt-44">
            <SectionProduct />
        </div>
        </div>
      </div>
      <div className="container relative space-y-24 mb-10 lg:space-y-15 lg:mb-10">
        <div className="relative py-0">
            <SectionDestinationFavorite 
            heading="Destinasi Populer 🗺️"
            subHeading="Tujuan wisata terkenal yang wajib dijelajahi"
            categoryCardType="card"
            itemPerRow={5}
            uniqueClassName="PageHome_s1"
            />
        </div>
        <div className="relative py-0">
            <SectionGridCategoryTab />
        </div>
      </div>
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <div className=" lg:hidden">
          <SectionDowloadApp />
        </div>
       <div className="hidden lg:flow-root">
        <SectionDowloadApp />
        </div>
        <SectionWhyChooseMe />
          
        </div>
    </div>
  );
}

export default PageHome;
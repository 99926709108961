import React, { FC, useEffect, useRef, useState } from "react";
import Logo from "shared/Logo/Logo";
import useOutSideAlerter from "hooks/useOutsideAlerter";
import LanguageDown from "./LanguageDown";
import CurrencyDown from "./CurrencyDown";
//import HeroSearchForm, { SearchTab } from "components/SearchForm2/HeroSearchForm";
import { Link, useLocation } from "react-router-dom";
//import SwitchDarkMode from "shared/SwitchDarkMode";
import MenuBar from "shared/MenuBar/MenuBar";
//import { StaySearchFormFields } from "components/SearchForm2/StaySearchForm";
//import SearchFormMobile from "components/SearchFormMobile/SearchFormMobile";

interface HeaderProps {
    className?: string;
}

let WIN_PREV_POSITION = window.pageYOffset;

const Header: FC<HeaderProps> = ({ className = "" }) => {

    const headerInnerRef = useRef<HTMLDivElement>(null);

    //const [showHeroSearch, setShowHeroSearch] = useState<StaySearchFormFields | null>();

    //const [currentTab, setCurrentTab] = useState<SearchTab>("Motor");

    useOutSideAlerter(headerInnerRef, () => {
        //setShowHeroSearch(null);
        //setCurrentTab("Motor");
    });

    let location = useLocation();

    useEffect(() => {
        //setShowHeroSearch(null);
    }, [location]);

    //Hidden ketika scroll event
    useEffect(() => {
        window.addEventListener("scroll", handleEvent);
        return () => {
            window.removeEventListener("scroll", handleEvent);
        };
    }, []);

    const handleEvent = () => {
        window.requestAnimationFrame(handleHideSearchForm);
    };

    const handleHideSearchForm = () => {
        if (!document.querySelector("#nc-Header-anchor")) {
            return;
        }
        //
        let currentScrollPos = window.pageYOffset;
        if (
            WIN_PREV_POSITION - currentScrollPos > 100 ||
            WIN_PREV_POSITION - currentScrollPos < -100
        ) {
            //setShowHeroSearch(null);
        } else {
            return;
        }
        WIN_PREV_POSITION = currentScrollPos;
    };


    return (
        <>
        <div
            className={`nc-Header nc-Header-3 fixed z-40 top-0 inset-0 bg-black/30 dark:bg-black/50 transition-opacity will-change-[opacity] invisible opacity-0 pointer-events-none"
            }`}
        ></div>
        
        <header ref={headerInnerRef} className={`sticky top-0 z-40 ${className}`}>
            
            <div className="relative px-4 lg:container h-[88px] flex">
            <div className="flex-1 flex items-center justify-between">
                {/* Logo (lg+) */}
                <div className="relative z-10 hidden md:flex flex-1">
                <Logo />
                </div>

                <div className="flex-[2] lg:flex-none mx-auto">
                <div className="hidden lg:block">
                </div>
                <div className="lg:hidden w-full max-w-lg mx-auto">
                </div>
                </div>

                {/* NAV */}
                <div className="hidden md:flex relative z-10 flex-1 items-center justify-end text-neutral-700 dark:text-neutral-100">
                <div className="items-center flex space-x-1">
                    <CurrencyDown />
                    <LanguageDown />

                    <div></div>
                    <div className="hidden md:block">
                    <MenuBar />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </header>
        </>
    );
}



export default Header;
import React, { useState, Fragment, useEffect } from "react";
import { Transition, Dialog } from "@headlessui/react";
import NavMobile from "shared/Navigation/NavMobile";
import { useLocation } from "react-router-dom";

export interface MenuBarProps {
    className?: string;
    iconClassName?: string;
}

const MenuBar: React.FC<MenuBarProps> = ({
    className = "p-2.5 rounded-lg text-neutral-700 dark:text-neutral-300",
    iconClassName = "h-7 w-7",
}) => {
    const [isVisable, setIsVisible] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setIsVisible(false);
    }, [location]);

    const handleOpenMenu = () => setIsVisible(true);
    const handleCloseMenu = () => setIsVisible(false);

    const renderContent = () => {
        return (
            <Transition appear show={isVisable} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={handleCloseMenu}>
                    <Transition.Child
                    as={Fragment}
                    enter="duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full ">
                        <Transition.Child
                            as={Fragment}
                            enter="transition duration-100 transform"
                            enterFrom="opacity-0 -translate-x-14"
                            enterTo="opacity-100 translate-x-0"
                            leave="transition duration-150 transform"
                            leaveFrom="opacity-100 translate-x-0"
                            leaveTo="opacity-0 -translate-x-14"
                        >
                            <Dialog.Panel className="w-full max-w-sm transform overflow-hidden transition-all">
                            <NavMobile onClickClose={handleCloseMenu} />
                            </Dialog.Panel>
                        </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        );
    };

    return (
        <>
        <button
            onClick={handleOpenMenu}
            className={`focus:outline-none flex items-center justify-center ${className}`}
        >
            <svg xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth="1.5" 
            stroke="currentColor" 
            className={iconClassName}>
                <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
            </svg>

        </button>

        {renderContent()}
        </>
    )
}

export default MenuBar;
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import headerImg from "images/dowloadApp.png";
import SectionCategoryMotor from "components/SectionCategory/SectionCategoryMotor";

export interface PageMotorProps {
    className?: string;
}

const PageMotor: FC<PageMotorProps> = ({ className = "" }) => {
    return (
        <div
        className={`nc-PageMotor relative overflow-hidden ${className}`} data-nc-id="PageMotor">
            <Helmet>
                <title>Motor - MainkeBali</title>
            </Helmet>
            <BgGlassmorphism />

            <div className="container relative space-y-4 mb-4 lg:space-y-18 lg:mb-20">
                <div className="hidden lg:flow-root">
                    <div className="rounded-3xl relative aspect-w-16 aspect-h-12 sm:aspect-h-8 lg:aspect-h-7 xl:aspect-h-5 overflow-hidden">
                        <div className="nc-NcImage absolute inset-0" data-nc-id="NcImage">
                            <img className="object-cover w-full h-full" src={headerImg} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container relative space-y-5 mb-5 lg:space-y-28 lg:mb-28">
                <SectionCategoryMotor />
            </div>
        </div>
    );
};

export default PageMotor;
import Heading from "components/Heading/Heading";
import { Tab } from "@headlessui/react";
import CardMotor from "components/CardCategory/CardMotor";
import { MOTOR_LISTING } from "data/listings";
import React, { FC, useState } from "react";

export interface SectionCategoryMotorProps{
    className?: string;
}

const SectionCategoryMotor: FC<SectionCategoryMotorProps> = ({ className="" }) => {
    let [categoriesMotor] = useState([]);

    const renderMotor = () => {
        return (
            <div className={`nc-SectionCategoryMotor relative ${className}`}>
                <Heading
                desc="Klik motor untuk membuat pesanan"
                >
                    🛵 Pilihan Rental Motor
                </Heading>
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-4 sm:grid-cols-5">
                    {MOTOR_LISTING.filter((_, i) => i < 8).map((motor) => (
                    <CardMotor key={motor.id} data={motor} />
                  ))}
                </div>
            </div>
        );
    };
    return (
        <div className={`grid gap-5 sm:gap-6 md:gap-5`}>
            {renderMotor()}
        </div>
    );
};

export default SectionCategoryMotor;
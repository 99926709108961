import { Link } from "react-router-dom";
import Heading from "components/Heading/Heading";
import iConMotor from "images/icon/iconmotor.png";
import iConPeralatanBayi from "images/icon/iconperalatanbayi.png";
import iConPaketTour from "images/icon/iconpakettour.png";
import iConPulsa from "images/icon/iconpulsa.png";
import iConShop from "images/icon/iconshop.png";
import { FC } from "react";

export interface SectionProductProps {
  className?: string;
}

const SectionProduct: FC<SectionProductProps> = ({ className = "" }) => {
  return (
    <div className="nc-SectionProduct [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] p-4 sm:p-8 xl:py-5 md:px-4 md:mr-4 md:ml-4 lg:mr-24 lg:ml-24 sm:mr-2 sm:ml-2 xl:mr-40 xl:ml-40 bg-white bg-opacity-20 backdrop-filter backdrop-blur-2xl shadow-2xl rounded-3xl space-y-3 sm:space-y-5 !border-opacity-0 --  nc-dark-box-bg">
      <div className="mt-5 md:mt-0 md:ml-8 xl:ml-1 flex-grow">
        <Heading
          isCenter
          className="dark:text-white text-secondary-900 md:text-secondary-900 sm:text-secondary-900 lg:text-secondary-900"
        >
          Mau cari apa hari ini?
        </Heading>
        <div className="mt-8 xl:mt-10 grid grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-6 sm:gap-5 xl:gap-2">
          <Link to="/motor">
            <div className="rounded-2xl flex flex-col items-center justify-center sm:shadow-lg sm:border md:shadow-lg md:border dark:border-neutral-800 md:border-neutral-200 md:border-opacity-70 md:hover:bg-neutral-50 md:hover:shadow-2xl hover:border-transparent md:transition-shadow p-5 lg:p-6">
              <div className="text-center relative flex items-center justify-center">
                <div className="relative">
                  <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                    <div className="wil-avatar rounded-full bg-slate-200 bg-opacity-100 hover:bg-opacity-50 relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 w-12 h-12 text-2xl ">
                      <img
                        className="object-cover w-6 rounded-lg items-center justify-center"
                        src={iConMotor}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <span className="font-medium text-center justify-center mt-10 text-xs sm:text-xs md:text-sm lg:text-base sm:mt-10 dark:text-white">
                Rental Motor
              </span>
            </div>
          </Link>
          <Link to="/baby-equipments">
            <div className="rounded-2xl flex flex-col items-center justify-center sm:shadow-lg sm:border md:shadow-lg md:border dark:border-neutral-800 md:border-neutral-200 md:border-opacity-70 md:hover:bg-neutral-50 md:hover:shadow-2xl hover:border-transparent md:transition-shadow p-5 lg:p-6">
              <div className="text-center relative flex items-center justify-center">
                <div className="relative">
                  <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                    <div className="wil-avatar rounded-full bg-slate-200 bg-opacity-100 hover:bg-opacity-50 relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 w-12 h-12 text-2xl ">
                      <img
                        className="object-cover w-6 rounded-lg items-center justify-center"
                        src={iConPeralatanBayi}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <span className="font-medium text-center justify-center mt-10 text-xs sm:text-xs md:text-sm lg:text-base sm:mt-10 dark:text-white">
                Peralatan Bayi
              </span>
            </div>
          </Link>
          <Link to="/tour-packages">
            <div className="rounded-2xl flex flex-col items-center justify-center sm:shadow-lg sm:border md:shadow-lg md:border dark:border-neutral-800 md:border-neutral-200 md:border-opacity-70 md:hover:bg-neutral-50 md:hover:shadow-2xl hover:border-transparent md:transition-shadow p-5 lg:p-6">
              <div className="text-center relative flex items-center justify-center">
                <div className="relative">
                  <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                    <div className="wil-avatar rounded-full bg-slate-200 bg-opacity-100 hover:bg-opacity-50 relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 w-12 h-12 text-2xl ">
                      <img
                        className="object-cover w-6 rounded-lg"
                        src={iConPaketTour}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <span className="font-medium text-center justify-center mt-10 text-xs sm:text-xs md:text-sm lg:text-base sm:mt-10 dark:text-white">
                Paket Wisata
              </span>
            </div>
          </Link>
          <Link to="/recharges">
            <div className="rounded-2xl flex flex-col items-center justify-center sm:shadow-lg sm:border md:shadow-lg md:border dark:border-neutral-800 md:border-neutral-200 md:border-opacity-70 md:hover:bg-neutral-50 md:hover:shadow-2xl hover:border-transparent md:transition-shadow p-5 lg:p-6">
              <div className="text-center relative flex items-center justify-center">
                <div className="relative">
                  <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                    <div className="wil-avatar rounded-full bg-slate-200 bg-opacity-100 hover:bg-opacity-50 relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 w-12 h-12 text-2xl ">
                      <img
                        className="object-cover w-4 rounded-sm"
                        src={iConPulsa}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <span className="font-medium text-center justify-center mt-10 text-xs sm:text-xs md:text-sm lg:text-base sm:mt-10 dark:text-white">
                Jual Pulsa
              </span>
            </div>
          </Link>
          <Link to="/shops">
            <div className="rounded-2xl flex flex-col items-center justify-center sm:shadow-lg sm:border md:shadow-lg md:border dark:border-neutral-800 md:border-neutral-200 md:border-opacity-70 md:hover:bg-neutral-50 md:hover:shadow-2xl hover:border-transparent md:transition-shadow p-5 lg:p-6">
              <div className="text-center relative flex items-center justify-center">
                <div className="relative">
                  <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                    <div className="wil-avatar rounded-full bg-slate-200 bg-opacity-100 hover:bg-opacity-50 relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 w-12 h-12 text-2xl ">
                      <img
                        className="object-cover w-6 rounded-lg"
                        src={iConShop}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <span className="font-medium text-center justify-center mt-10 tex text-xs sm:text-xs md:text-sm lg:text-base sm:mt-10 dark:text-white">
                Toko Belanja
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SectionProduct;

import React, { FC, useState } from "react";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import { Linking } from "react-native";
import moment from "moment";
import RentalMotorDatesInput from "containers/DatesInput/RentalMotorDatesInput";
import arrow from "images/icon-arrow-down.svg";
import ModalPhotos from "./modalPhotos";

export interface Vario110Props {
  defaultValue: moment.Moment | null;
  onChange?: (date: moment.Moment | null) => void;
  defaultFocus?: boolean;
  fieldClassName?: string;
  onFocusChange?: (focused: boolean) => void;
  className?: string;
}

const PHOTOS: string[] = [
  "https://images.pexels.com/photos/381292/pexels-photo-381292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2526128/pexels-photo-2526128.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/2827753/pexels-photo-2827753.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/1637859/pexels-photo-1637859.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/257851/pexels-photo-257851.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/189454/pexels-photo-189454.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/193995/pexels-photo-193995.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  "https://images.pexels.com/photos/575386/pexels-photo-575386.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

const include_Motor = [
  { name: "Gratis Antar Jemput" },
  { name: "2 Helm SNI" },
  { name: "1 Jas Hujan" },
  { name: "Tempat HP di motor" },
  { name: "Motor selalu bersih dan terawat" },
];

const DetailVario110: FC<Vario110Props> = ({ className = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openFocus, setOpenFocus] = useState(0);

  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: moment(),
    endDate: moment().add(2, "days"),
  });

  const [syarat, setSyarat] = useState(false);
  const [perjanjian, setPerjanjian] = useState(false);
  const [kendala, setKendala] = useState(false);
  const [batal, setBatal] = useState(false);

  const handleSubmit = () => {
    let selectStartDate = dateRangeValue.startDate.format("DD MMM YYYY");
    let selectEndDate = dateRangeValue.endDate.format("DD MMM YYYY");

    let nameMotor = "Vario Fi 110 thn 2014";

    let linkWA =
      "https://wa.me/6282130000822?text=Hi%20MainkeBali,%0aSaya%20mau%20sewa%20motor:%20" +
      nameMotor +
      ",%0aTanggal%20Antar:%20" +
      selectStartDate +
      "%20-%20Sampai%20Tanggal:%20" +
      selectEndDate +
      "%0aApakah%20ready%20motornya%20min?";
    Linking.openURL(linkWA, "_blank");
    console.log(selectStartDate, selectEndDate);
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocus(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderContent1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        <div className="flex justify-between items-center">
          <Badge color="green" name="Honda" />
        </div>

        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          Vario Fi 110
        </h2>
        <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 text-sm">
          <div className="flex items-center ">
            <span className="ml-2 text-neutral-500 dark:text-neutral-400 flex flex-col">
              <span className="text-md">🏷️ Harian</span>
              <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
                <span>Rp. 50.000</span>
                <span className="ml-1">
                  <svg className="w-4 h-4" viewBox="0 0 17 17" fill="none">
                    <path
                      d="M7.66691 2.62178C8.12691 2.22845 8.88025 2.22845 9.34691 2.62178L10.4002 3.52845C10.6002 3.70178 10.9736 3.84178 11.2402 3.84178H12.3736C13.0802 3.84178 13.6602 4.42178 13.6602 5.12845V6.26178C13.6602 6.52178 13.8002 6.90178 13.9736 7.10178L14.8802 8.15512C15.2736 8.61512 15.2736 9.36845 14.8802 9.83512L13.9736 10.8884C13.8002 11.0884 13.6602 11.4618 13.6602 11.7284V12.8618C13.6602 13.5684 13.0802 14.1484 12.3736 14.1484H11.2402C10.9802 14.1484 10.6002 14.2884 10.4002 14.4618L9.34691 15.3684C8.88691 15.7618 8.13358 15.7618 7.66691 15.3684L6.61358 14.4618C6.41358 14.2884 6.04025 14.1484 5.77358 14.1484H4.62025C3.91358 14.1484 3.33358 13.5684 3.33358 12.8618V11.7218C3.33358 11.4618 3.19358 11.0884 3.02691 10.8884L2.12691 9.82845C1.74025 9.36845 1.74025 8.62178 2.12691 8.16178L3.02691 7.10178C3.19358 6.90178 3.33358 6.52845 3.33358 6.26845V5.12178C3.33358 4.41512 3.91358 3.83512 4.62025 3.83512H5.77358C6.03358 3.83512 6.41358 3.69512 6.61358 3.52178L7.66691 2.62178Z"
                      fill="#38BDF8"
                      stroke="#38BDF8"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M6.08691 8.98833L7.69358 10.6017L10.9136 7.375"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </span>
            </span>
          </div>
          <div className="hidden sm:block h-6 border-l border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex items-center">
            <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
              <span className="text-md">🏷️ Mingguan</span>
              <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
                <span>Rp. 50.000</span>
                <span className="ml-1">
                  <svg className="w-4 h-4" viewBox="0 0 17 17" fill="none">
                    <path
                      d="M7.66691 2.62178C8.12691 2.22845 8.88025 2.22845 9.34691 2.62178L10.4002 3.52845C10.6002 3.70178 10.9736 3.84178 11.2402 3.84178H12.3736C13.0802 3.84178 13.6602 4.42178 13.6602 5.12845V6.26178C13.6602 6.52178 13.8002 6.90178 13.9736 7.10178L14.8802 8.15512C15.2736 8.61512 15.2736 9.36845 14.8802 9.83512L13.9736 10.8884C13.8002 11.0884 13.6602 11.4618 13.6602 11.7284V12.8618C13.6602 13.5684 13.0802 14.1484 12.3736 14.1484H11.2402C10.9802 14.1484 10.6002 14.2884 10.4002 14.4618L9.34691 15.3684C8.88691 15.7618 8.13358 15.7618 7.66691 15.3684L6.61358 14.4618C6.41358 14.2884 6.04025 14.1484 5.77358 14.1484H4.62025C3.91358 14.1484 3.33358 13.5684 3.33358 12.8618V11.7218C3.33358 11.4618 3.19358 11.0884 3.02691 10.8884L2.12691 9.82845C1.74025 9.36845 1.74025 8.62178 2.12691 8.16178L3.02691 7.10178C3.19358 6.90178 3.33358 6.52845 3.33358 6.26845V5.12178C3.33358 4.41512 3.91358 3.83512 4.62025 3.83512H5.77358C6.03358 3.83512 6.41358 3.69512 6.61358 3.52178L7.66691 2.62178Z"
                      fill="#38BDF8"
                      stroke="#38BDF8"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M6.08691 8.98833L7.69358 10.6017L10.9136 7.375"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </span>
              </span>
            </span>
          </div>
        </div>

        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-calendar-alt text-2xl"></i>
            <span className=""> tahun 2014</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-gas-pump text-2xl"></i>
            <span className=""> 13 liter</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-dharmachakra text-2xl"></i>
            <span className=""> Otomotif</span>
          </div>
          <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
            <i className="las la-tachometer-alt text-2xl"></i>
            <span className=""> 110 cc</span>
          </div>
        </div>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            {include_Motor
              .filter((_, i) => i < 5)
              .map((item) => (
                <div key={item.name} className="flex items-center space-x-3">
                  <i className="las la-check-circle text-2xl"></i>
                  <span>{item.name}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const renderContent2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Syarat dan Kebijakan</h2>
        <div className="w-14 border-b border-neutral-200"></div>
        <div className="faq justify-center mt-[-5rem] md:mt-0">
          <div className="p-2 ">
            <div
              className="flex justify-between w-full px-4 py-2 font-medium hover:cursor-pointer text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75"
              onClick={() => setSyarat(!syarat)}
            >
              <p className={syarat ? "font-bold" : ""}>
                Syarat Peminjaman Motor
              </p>
              <img
                className={
                  "self-center" +
                  (syarat
                    ? " rotate-180 transition ease-in-out duration-300"
                    : "")
                }
                src={arrow}
                alt=""
              />
            </div>
            <div className="pt-2">
              <p
                className={
                  syarat
                    ? "px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400"
                    : " overflow-hidden h-0 "
                }
              >
                <div className="prose sm:prose">
                  <ul className="mt-3 text-neutral-500 dark:text-neutral-100 space-y-2">
                    <li>Foto KTP</li>
                    <li>Foto SIM C</li>
                    <li>Foto Pemesanan Tiket Pesawat</li>
                    <li>Foto Pemesanan Tiket Hotel</li>
                    <li>Minimal sewa 2 hari</li>
                  </ul>
                </div>
              </p>
            </div>
          </div>

          <div className="p-2">
            <div
              className="flex justify-between w-full px-4 py-2 font-medium hover:cursor-pointer text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75"
              onClick={() => setPerjanjian(!perjanjian)}
            >
              <p className={perjanjian ? "font-bold" : ""}>
                Perjanjian Peminjaman Motor
              </p>
              <img
                className={
                  "self-center" +
                  (perjanjian
                    ? " rotate-180 transition ease-in-out duration-300"
                    : "")
                }
                src={arrow}
                alt=""
              />
            </div>
            <div className="pt-2">
              <p
                className={
                  perjanjian
                    ? "px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400"
                    : " overflow-hidden h-0 "
                }
              >
                <div className="prose sm:prose">
                  <ul className="mt-3 text-neutral-500 dark:text-neutral-100 space-y-2">
                    <li>Harga sewa kendaraan perhari 24 jam</li>
                    <li>
                      Penyewa bertanggung jawab penuh atas setiap kerusakan,
                      kehilangan / kecelakaan unit yang disewa
                    </li>
                    <li>
                      Kelebihan maksimum 2 jam, lebih dari 2 jam akan dikenakan
                      biaya sewa 1 hari
                    </li>
                    <li>
                      Akan dikenakan biaya kehilangan aksesoris perlengkapan
                      sewa : Helm 100.000/pcs
                    </li>
                    <li>Kendaraan hanya dapat digunakan dalam Pulau Bali</li>
                  </ul>
                </div>
              </p>
            </div>
          </div>
          <div className="p-2">
            <div
              className="flex justify-between w-full px-4 py-2 font-medium hover:cursor-pointer text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75"
              onClick={() => setKendala(!kendala)}
            >
              <p className={kendala ? "font-bold" : ""}>
                Bagaimana jika ada kendala pada motor?
              </p>
              <img
                className={
                  "self-center" +
                  (kendala
                    ? " rotate-180 transition ease-in-out duration-300"
                    : "")
                }
                src={arrow}
                alt=""
              />
            </div>
            <div className="pt-2">
              <p
                className={
                  kendala
                    ? "px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-100"
                    : " overflow-hidden h-0 "
                }
              >
                Jika hari pertama sewa, ajukan penukaran motor, maka unit akan
                langsung di tukar. Setelah hari kedua maka menjadi tanggung
                jawab penyewa.
              </p>
            </div>
          </div>
          <div className="p-2">
            <div
              className="flex justify-between w-full px-4 py-2 font-medium hover:cursor-pointer text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75"
              onClick={() => setBatal(!batal)}
            >
              <p className={batal ? "font-bold" : ""}>
                Bisakah saya membatalkan pesanan?
              </p>
              <img
                className={
                  "self-center" +
                  (batal
                    ? " rotate-180 transition ease-in-out duration-300"
                    : "")
                }
                src={arrow}
                alt=""
              />
            </div>
            <div className="pr-3 pt-2">
              <p
                className={
                  batal
                    ? "px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-100"
                    : " overflow-hidden h-0 "
                }
              >
                Bisa, anda dapat membatalkan pesanan sehari sebelum tanggal
                antar.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        <div className="flex text-center items-center justify-center">
          <span className="text-xl font-semibold text-center items-center justify-center">
            Pesan Sekarang
          </span>
        </div>

        {/* FORM */}
        <form className="flex border  border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <RentalMotorDatesInput
            defaultDateValue={dateRangeValue}
            numberOfMonths={2}
            fieldClassName="p-3"
            wrapFieldClassName="flex flex-col w-full flex-shrink-0 relative divide-y divide-neutral-200 dark:divide-neutral-700"
            className="RentalCarDetailPageDatesRangeInput flex-1"
            onChange={(data) => {
              setDateRangeValue(data.stateDate);
            }}
            anchorDirection={"right"}
          />
        </form>

        {/* SUBMIT */}
        <button
          onClick={handleSubmit}
          className="tnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50 font-normal  py-2 px-4 rounded-full"
        >
          Kirim Permintaan
        </button>
      </div>
    );
  };

  return (
    <div
      className={`nc-DetailVario110 ${className}`}
      data-nc-id="DetailVario110"
    >
      <>
        <header className="container xl:px-30 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS[0]}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>

            {/*  */}
            <div
              className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(1)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={PHOTOS[1]}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>

            {/*  */}
            {PHOTOS.filter((_, i) => i >= 2 && i < 4).map((item, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 2 ? "block" : ""
                }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 2)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocus}
          uniqueClassName="nc-ListingCarDetailPage__modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 mb-10 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          {renderContent1()}
          {renderContent2()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}

      {/* OTHER SECTION */}
    </div>
  );
};

export default DetailVario110;

import Logo from "shared/Logo/Logo";
import SocialsList from "shared/SocialsList/SocialsList";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
    {
        id: "1",
        title: "Tentang MainkeBali",
        menus: [
            { href: "#", label: "Tentang Kami" },
            { href: "#", label: "Pusat Bantuan" },
            { href: "#", label: "FAQ" },
            { href: "#", label: "Syarat dan Ketentuan" },
            { href: "#", label: "Blog" },
        ],
    },
    {
        id: "2",
        title: "Halaman",
        menus: [
            { href: "#", label: "Rental Motor" },
            { href: "#", label: "Perlengkapan Bayi" },
            { href: "#", label: "Paket Wisata" },
            { href: "#", label: "Pulsa" },
            { href: "#", label: "Shop" },
        ],
    },    
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };
    
    return (
        <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
        <div className="container grid grid-cols-2 gap-y-10 gap-x-4 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-10 ">
            <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
            <div className="col-span-2 md:col-span-1">
                <Logo />
            </div>
            <div className="col-span-2 flex items-center md:col-span-3">
                <SocialsList className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
            </div>
            </div>
            {widgetMenus.map(renderWidgetMenuItem)}
            <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
            <div className="col-span-2 md:col-span-1">
                <h2 className="font-semibold text-neutral-700 dark:text-neutral-200 text-lg">Hotline 24 Jam</h2>
            </div>
            <div className="col-span-2 md:col-span-1">
            <div className="flex items-center leading-none text-sm">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M7 4H17V18H7V4Z" fill="#087E8B"/>
                <path d="M16 1H8C6.34 1 5 2.34 5 4V20C5 21.66 6.34 23 8 23H16C17.66 23 19 21.66 19 20V4C19 2.34 17.66 1 16 1ZM14 21H10V20H14V21ZM17 18H7V4H17V18Z" fill="#087E8B"/>
                </svg>
                &nbsp;+62 821 30000 822
            </div>
            </div>
            <div className="col-span-2 md:col-span-1">
            <div className="flex items-center leading-none text-sm">
                <h2 className="font-semibold text-neutral-700 dark:text-neutral-200 text-lg">Download App</h2>
            </div>
            </div>
            </div>
            
        </div>
        </div>
    );
};

export default Footer;
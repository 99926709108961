import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import PageAbout from "containers/PageAbout/PageAbout";
import BlogPage from "containers/PageBlog/BlogPage";
import PageHelpCenter from "containers/PageHelpCenter/PageHelpCenter";
import PageFaq from "containers/PageFaq/PageFaq";
import PageTermsConditions from "containers/PageTermsConditions/PageTermsConditions";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import PageMotor from "containers/PageProduk/PageMotor";
import DetailVario110 from "containers/PageDetailMotor/DetailVario110";
import DetailVario125 from "containers/PageDetailMotor/DetailVario125";
import DetailBeatEsp from "containers/PageDetailMotor/DetailBeatEsp";
import DetailScoopyStylish from "containers/PageDetailMotor/DetailScoopyStylish";
import DetailNmax from "containers/PageDetailMotor/DetailNmax";
import DetailPcx from "containers/PageDetailMotor/DetailPcx";
import DetailGenioCbs from "containers/PageDetailMotor/DetailGenioCbs";
import DetailBeatStreet from "containers/PageDetailMotor/DetailBeatStreet";
import PageBabyEquipment from "containers/PageProduk/PageBabyEquipment";
import PageTourPackage from "containers/PageProduk/PageTourPackage";
import PageRecharge from "containers/PageProduk/PageRecharge";
import PageShop from "containers/PageProduk/PageShop";
import useWindowSize from "hooks/useWindowResize";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },

  //PageProduk
  { path: "/motor", component: PageMotor },
  { path: "/baby-equipment", component: PageBabyEquipment },
  { path: "/tour-package", component: PageTourPackage },
  { path: "/recharge", component: PageRecharge },
  { path: "/shop", component: PageShop },

  //PageProdukMotorDetail
  { path: "/detail-vario110", component: DetailVario110 },
  { path: "/detail-vario125", component: DetailVario125 },
  { path: "/detail-beat-esp", component: DetailBeatEsp },
  { path: "/detail-scoopy-stylish", component: DetailScoopyStylish },
  { path: "/detail-nmax", component: DetailNmax },
  { path: "/detail-pcx", component: DetailPcx },
  { path: "/detail-genio-cbs", component: DetailGenioCbs },
  { path: "/detail-beat-street", component: DetailBeatStreet },

  //Footer
  { path: "/about", component: PageAbout },
  { path: "/blog", component: BlogPage },
  { path: "/help-center", component: PageHelpCenter },
  { path: "/faq", component: PageFaq },
  { path: "/terms-conditions", component: PageTermsConditions },
];

const Routes = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <SiteHeader />

      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;

import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/icon/iconpraktistanparibet.png";
import HIW2img from "images/icon/iconlayananterpecaya.png";
import HIW3img from "images/icon/iconcustomerservice.png";
import HIW4img from "images/icon/iconkeamanandata.png";
import VectorImg from "images/VectorHIW.svg";

export interface SectionWhyChooseMeProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionWhyChooseMeProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "Praktis, Tanpa Ribet",
    desc: "Pesan dimana saja dan kapan saja tanpa perlu repot pembayaran dan booking.",
  },
  {
    id: 2,
    img: HIW2img,
    title: "Layanan Terpecaya",
    desc: "Anda akan mendapatkan layanan yang memuaskan dari MainkeBali.",
  },
  {
    id: 3,
    img: HIW3img,
    title: "Customer Service",
    desc: "Selalu siap untuk pemesanan dan menjawab pertanyaan, keluhan dari anda.",
  },
  {
    id: 4,
    img: HIW4img,
    title: "Jaminan Keamanan",
    desc: "Pesan di MainkeBali akan menjamin privasi dan keamanan data anda.",
  },
];

const SectionWhyChooseMe: FC<SectionWhyChooseMeProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionWhyChooseMe  ${className}`}
      data-nc-id="SectionWhyChooseMe"
    >
     
      <Heading isCenter desc="&nbsp;">
        Mengapa memesan di MainkeBali?
        
      </Heading>
      
      <div className="mt-20 relative grid grid-cols-2 md:grid-cols-4 gap-20">
        
        
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            {item.imgDark ? (
              <>
                <NcImage
                  containerClassName="dark:hidden block mb-8 max-w-[200px] mx-auto"
                  src={item.img}
                />
                <NcImage
                  containerClassName="hidden dark:block mb-8 max-w-[200px] mx-auto"
                  src={item.imgDark}
                />
              </>
            ) : (
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={item.img}
              />
            )}
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-sm md:text-base text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionWhyChooseMe;

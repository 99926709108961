import __motorListing from "./jsons/__motorListing.json";
import __wisataListing from "./jsons/__tourListing.json";
import __kulinerListing from "./jsons/__culinaryListing.json";
import {
  MOTOR_CATEGORIES,
  WISATA_CATEGORIES,
  KULINER_CATEGORIES,
} from "./taxonomies";
import { MotorDataType, KulinerDataType, WisataDataType } from "./types";
import { DEMO_AUTHORS } from "./authors";
//
import motor1 from "images/motor/variofi110thn2014.png";
import motor2 from "images/motor/hondavario125cbs.png";
import motor3 from "images/motor/hondabeatesp2018.png";
import motor4 from "images/motor/scoopystylish2019.png";
import motor5 from "images/motor/nmax2022.png";
import motor6 from "images/motor/pcx.png";
import motor7 from "images/motor/genio2022.png";
import motor8 from "images/motor/beatstreet.png";

const motorImgs = [
  motor1,
  motor2,
  motor3,
  motor4,
  motor5,
  motor6,
  motor7,
  motor8,
];

const WISATA_LISTINGS = __wisataListing.map((post, index): WisataDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = WISATA_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `wisataListing_${index}_`,
    saleOff: !index ? "Terbaru" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const KULINER_LISTINGS = __kulinerListing.map(
  (post, index): KulinerDataType => {
    //  ##########  GET CATEGORY BY CAT ID ######## //
    const category = KULINER_CATEGORIES.filter(
      (taxonomy) => taxonomy.id === post.listingCategoryId
    )[0];

    return {
      ...post,
      id: `kulinerListing_${index}_`,
      saleOff: !index ? "Terbaru" : post.saleOff,
      isAds: !index ? true : post.isAds,
      author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
      listingCategory: category,
    };
  }
);

const MOTOR_LISTING = __motorListing.map((post, index): MotorDataType => {
  const category = MOTOR_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `motorListing_${index}_`,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
    featuredImage: motorImgs[index],
  };
});

export { MOTOR_LISTING, WISATA_LISTINGS, KULINER_LISTINGS };

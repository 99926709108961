import React, { FC, Fragment, useState } from "react";
import Heading from "components/Heading/Heading";
import { Tab } from "@headlessui/react";
import WisataCard from "components/WisataCard/WisataCard";
import KulinerCard from "components/KulinerCard/KulinerCard";
import {
  WISATA_LISTINGS,
  KULINER_LISTINGS,
} from "data/listings";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionGridCategoryTabProps {
  className?: string;
}

const SectionGridCategoryTab: FC<SectionGridCategoryTabProps> = ({ className = "" }) => {
  let [categories] = useState(["Wisata", "Kuliner"]);

  const renderSection1 = () => {
  return (
    <div className={`nc-SectionGridFeaturePlaces relative ${className}`}>
      <Heading
        desc="Temukan tempat-tempat menarik di Bali untuk menemani perjalanan anda."
      >
        ⛱ Pilihan Pengalaman Favorit
      </Heading>
      <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-4 sm:grid-cols-5">
                  {WISATA_LISTINGS.filter((_, i) => i < 5).map((wisata) => (
                    <WisataCard key={wisata.id} data={wisata} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-4 sm:grid-cols-5">
                  {KULINER_LISTINGS.filter((_, i) => i < 5).map(
                    (kuliner) => (
                      <KulinerCard key={kuliner.id} data={kuliner} />
                    )
                  )}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
    </div>
  );
  };
  return (
  <div className={`grid gap-5 sm:gap-6 md:gap-8`}>
        {renderSection1()}
      </div>
);
};

export default SectionGridCategoryTab;
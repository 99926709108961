import React from "react";

export interface NavMobileProps {
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
    onClickClose,
})=> {
    return (
    <div className="overflow-y-auto w-full max-w-sm h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-6 px-5">
        
        <div className="flex flex-col mt-5 text-neutral-700 dark:text-neutral-300 text-sm">
          <span>
            Discover the most outstanding articles on all topics of life. Write
            your stories and share them
          </span>

          <div className="flex justify-between items-center mt-4">
            
            <span className="block">
              
            </span>
          </div>
        </div>
        <span className="absolute right-2 top-2 p-1">
        </span>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">
        
      </ul>
      <div className="flex items-center justify-between py-6 px-5">
        <a
          className="inline-block"
          href="https://themeforest.net/item/chisfis-online-booking-react-template/33515927"
          target="_blank"
          rel="noopener noreferrer"
        >
          
        </a>

        
      </div>
    </div>
  );
}

export default NavMobile;